<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách đóng tiền</h3>
      </div>
      <div class="card-title" v-if="currentUser.user.permission.includes(permission.EXPORT_DANH_SACH_DONG_TIEN)">
        <div>
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel
          </button>
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcelV2">
            <i class="la la-file-excel"></i> Xuất excel v2
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel v2
          </button>
          <button v-if="!is_export_excel" class="btn btn-primary font-weight-bold mr-2" @click="dialogImport = true">
            <i class="el-icon-upload"></i> Import
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Tên, SĐT, Mã học viên
                <span class="text-danger"></span></label>
              <el-input type="text" v-model="query.keyword"
                        placeholder="Tên, SĐT, Mã học viên"></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Phân loại nạp, rút, hoàn</label>
              <el-select v-model="query.type_nap_rut" clearable filterable placeholder="Chọn loại" class="w-100">
                <el-option v-for="item in TYPE_NAP_RUT"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Trạng thái</label>
              <el-select v-model="query.status" clearable filterable placeholder="Trạng thái" class="w-100">
                <el-option v-for="status in STUDENT_AMOUNT_STATUS"
                           :key="status.value"
                           :label="status.label"
                           :value="status.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Ngày nạp
                <span class="text-danger"></span></label>
              <date-picker
                  placeholder="Chọn thời gian"
                  format="DD-MM-YYYY"
                  range
                  valueType="YYYY-MM-DD" v-model="query.date_range">
              </date-picker>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Trung tâm</label>
              <el-select v-model="query.centerIds"
                         clearable filterable multiple
                         :collapse-tags="true"
                         placeholder="Chọn trung tâm" class="w-100"
                         @change="handleQueryCenter"
              >
                <el-option v-for="item in centers"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Chi nhánh</label>
              <el-select v-model="query.branchIds"
                         clearable filterable multiple
                         :collapse-tags="true"
                         placeholder="Chọn chi nhánh" class="w-100">
                <el-option v-for="item in branches"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Kênh khách hàng</label>
              <el-select v-model="query.source"
                         clearable filterable
                         :collapse-tags="true"
                         placeholder="Chọn kênh khách hàng" class="w-100">
                <el-option v-for="item in customerChannelOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="d-flex justify-content-between">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                      class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3 mt-5">
            Tổng doanh số:
            <span class="label label-info label-inline mr-2 h6 bold">{{
                student_amounts.data !== undefined && student_amounts.data[0] !== undefined ?
                    formatPrice(student_amounts.data[0].total_statistic) : '0đ'
              }}</span>
          </div>
          <div class="col-md-12 mb-3">
            Số lượt nộp :
            <span class="label label-danger label-inline mr-2 h6 bold">{{ student_amounts.total }}</span>
          </div>
        </div>
        <div class="example-preview b-table-sticky-header">
          <table :aria-busy="isBusy" class="table table-bordered table-hover b-table">
            <thead class="thead-light">
            <tr>
              <th scope="col" class="min-w-20px">STT</th>
              <th scope="col" class="min-w-100px">Mã hóa đơn</th>
              <th scope="col" class="min-w-200px">Tên khách hàng</th>
              <th scope="col" class="min-w-100px">Số điện thoại</th>
              <th scope="col" class="min-w-110px">Mã hợp đồng</th>
              <th scope="col" class="min-w-120px">Chi nhánh học</th>
              <th scope="col" class="min-w-120px">Số tiền nạp</th>
              <th scope="col" class="min-w-100px">Phí trả góp</th>
              <th scope="col" class="min-w-100px">Kênh KH</th>
              <th scope="col" class="min-w-100px">Nguồn KH</th>
              <th scope="col" class="min-w-120px">Mã giới thiệu</th>
              <th scope="col" class="min-w-100px">Mã tư vấn</th>
              <th scope="col" class="min-w-100px">Hệ số Đội</th>
              <th scope="col" class="min-w-100px">Hệ số Cá nhân</th>
              <th scope="col" class="min-w-100px">Hệ số lên cấp</th>
              <th scope="col" class="min-w-110px">Thời gian nạp</th>
              <th scope="col" class="min-w-120px">Hành động</th>
            </tr>
            </thead>
            <tbody v-if="isBusy">
               <tr role="row" class="b-table-busy-slot" >
                 <td colspan="16" role="cell" class="text-center">
                   <div class="text-center text-danger my-2">
                     <b-spinner class="align-middle"></b-spinner>
                     <strong>Loading...</strong>
                   </div>
                 </td>
               </tr>
             </tbody>
            <tbody v-else-if="student_amounts.data && student_amounts.data.length > 0">
              <tr v-for="(item, index) in student_amounts.data" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.id }}</td>
                <td>{{ item.user !== null ? item.user.name : '' }}</td>
                <td>{{ item.user !== null ? item.user.phone : '' }}</td>
                <td>{{ item.contract_id ?? '' }}</td>
                <td>{{ item.chi_nhanh_hoc_dsdt !== null ? item.chi_nhanh_hoc_dsdt.name : '' }}</td>
                <td>{{ formatPrice(item.amount, 'đ') }}</td>
                <td>{{ item.is_installment === 1 ? formatPrice(-(item.amount-item.amount_installment), 'đ') : '0đ' }}</td>
                <td>{{ changeCustomerChannel(item.customer_channel) }}</td>
                <td>
                  {{ item.contract && item.contract.customer ? item.contract.customer.nguon : '' }}
                </td>
                <td>{{ item.nguoi_gt_id ? item.nguoi_gt_id + 100000 : '' }}</td>
                <td>{{ item.nguoi_tu_van ? item.nguoi_tu_van + 100000 : '' }}</td>
                <td>{{ item.business_coefficient }}</td>
                <td>{{ item.personal_coefficient }}</td>
                <td>{{ item.levelup_coefficient }}</td>
                <td>{{ item.dated_paid | formatDateTimeAsia }}</td>
                <td class="d-flex justify-content-around align-items-center sticky-action">
                  <a href="javascript:" v-if="currentUser.user.permission.includes(permission.SUA_THONG_TIN_DONG_TIEN)"
                     title="Sửa thông tin"
                     class="btn sm btn-icon btn-outline-info"
                     @click="openDialogUpdate(item)">
                    <i class="fas fa-pen-nib"></i>
                  </a>
                  <a href="javascript:" title="Xem lịch sử sửa thông tin" class="btn sm btn-icon btn-outline-success" @click="openDialogHistory(item)"><i class="far fa-eye"></i></a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="17" class="text-center">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Modal update hệ số kd, mgt, mtvv -->
    <el-dialog  class="responsive" :visible.sync="dialogUpdate" :title="`Sửa thông tin hóa đơn ${currentStudentAmount.id}`">
      <ModalEditAmount  @updateSuccess="updateSuccess" :contract-student-amount="currentStudentAmount"></ModalEditAmount>
    </el-dialog>
    <el-dialog class="responsive" :visible.sync="dialogHistory" title="Lịch sử sửa thông tin">
      <history-update :dialog-history="dialogHistory" :contract-student-amount="currentStudentAmount"></history-update>
    </el-dialog>
    <el-dialog  class="responsive" :visible.sync="dialogImport" title="Import thông tin hóa đơn">
      <modal-import @importSuccess="importSuccess"></modal-import>
    </el-dialog>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  EXPORT_CUSTOMER_PAYMENT, GET_CONTRACT_SOURCES,
  GET_STUDENT_AMOUNT, EXPORT_CUSTOMER_PAYMENT_V2
} from "@/core/services/store/contract/studentAmount.module";
import { GET_BRANCHES } from "@/core/services/store/center/branch.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CENTERS } from "@/core/services/store/center/centers.module";
import { STUDENT_AMOUNT_STATUS, TYPE_NAP_RUT } from '@/core/option/studentAmountOption'
import DatePicker from 'vue2-datepicker';
import ModalEditAmount from "@/view/pages/payments/components/ModalEditAmount";
import HistoryUpdate from "@/view/pages/payments/components/HistoryUpdate";
import ModalImport from "@/view/pages/payments/components/ModalImport";
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
import {exportExcel} from "@/core/filters";
const _ = require('lodash');

export default {
  components: {
    DatePicker,
    ModalEditAmount,
    HistoryUpdate,
    ModalImport
  },
  name: "PaymentLog",
  data() {
    return {
      /*Loader */
      isBusy: false,
      isRefund: false,
      dialogUpdate: false,
      dialogHistory: false,
      /* popover */
      options: [],
      /* end popover */
      is_disable_search: false,
      is_export_excel: false,
      branches: [],
      STUDENT_AMOUNT_STATUS,
      TYPE_NAP_RUT,
      centers: [],
      sources: [],
      student_amounts: [],
      // Paginate
      page: 1,
      last_page: 1,
      customerChannelOptions: [
        {
          label: 'Đội kinh doanh',
          value: 1,
        },
        {
          label: 'Digital marketing',
          value: 2,
        },
        {
          label: 'Liên kết trường',
          value: 3,
        },
      ],
      query: {
        centerIds: [],
        branchIds: [],
        date_range: [],
        keyword: '',
        type_nap_rut: 0,
        status: '',
        // Condition default
        other_type: 3,
        parent_log_id: 0,
        is_get_log: true,
        sourceIds: [],
        source: null,
      },
      date_filter_default: [],
      districtsByProvince: [],
      log_change_dated_paid: [],
      currentStudentAmount: {},
      dialogImport: false,
    }
  },
  mounted() {
    this.getDateFilterDefault();
    this.getCenters();
    this.getBranches();
    this.getSources();
    this.getList();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Báo cáo", route: '#'},
      {title: "Danh sách đóng tiền"}
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {},
  methods: {
    getDateFilterDefault() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      this.date_filter_default.push(startOfMonth);
      this.date_filter_default.push(endOfMonth);
    },
    updateSuccess() {
      this.dialogUpdate = false;
      this.getList();
    },
    importSuccess() {
      this.dialogImport = false;
      this.getList();
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.query.keyword = this.$route.query.keyword;
      this.query.centerIds = this.$route.query.centerIds ? this.$route.query.centerIds.map(item => Number(item)) : [];
      this.query.branchIds = this.$route.query.branchIds ?this.$route.query.branchIds.map(item => Number(item)) : [];
      this.query.sourceIds = this.$route.query.sourceIds ?this.$route.query.sourceIds.map(item => Number(item)) : [];
      this.query.date_range = this.$route.query.date_range ? this.$route.query.date_range : this.date_filter_default;
      this.query.type_nap_rut = this.$route.query.type_nap_rut ? Number(this.$route.query.type_nap_rut) : 0;
      this.query.status = this.$route.query.status ? Number(this.$route.query.status) : 1;
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'amount-log-list',
        query: {
          page: this.page,
          ...this.query,
          date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
        }
      })
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        path: '',
        query: {
          page: this.page,
          centerIds: this.query.centerIds,
          branchIds: this.query.branchIds,
          keyword: this.query.keyword,
          date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
          type_nap_rut: this.query.type_nap_rut
        }
      })
      this.callApiGetList()
    },
    callApiGetList() {
      this.isBusy = true;
      this.$store.dispatch(GET_STUDENT_AMOUNT, {
        page: this.page,
        ...this.query,
        date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
        is_danh_sach_dong_tien: 1,
      }).then((res) => {
        if (!res.error) {
          this.isBusy = false;
          this.is_disable_search = false;
          this.student_amounts = res.data;
          this.last_page = res.data.last_page;
          this.isRefund = this.query.type_nap_rut === 3;
        }
      }).finally(() => { this.isBusy = false;});
    },
    exportExcelV2() {
        this.is_export_excel = true
        const HEADER_ROW_EXPORT_DSDT = [
          {
            key: 'stt',
            value: 'STT',
          },
          {
            key: 'bill_id',
            value: 'Mã hóa đơn',
          },
          {
            key: 'name',
            value: 'Tên học viên',
          },
          {
            key: 'user_id',
            value: 'Mã học viên',
          },
          {
            key: 'phone',
            value: 'Số điện thoại',
          },
          {
            key: 'contract_id',
            value: 'Mã hợp đồng',
          },
          {
            key: 'amount',
            value: 'Số tiền nạp',
          },
          {
            key: 'amount_installment',
            value: 'Phí trả góp',
          },
          {
            key: 'center_name',
            value: 'Trung tâm',
          },
          {
            key: 'center_id',
            value: 'Mã trung tâm',
          },
          {
            key: 'chi_nhanh_hoc',
            value: 'Chi nhánh',
          },
          {
            key: 'chi_nhanh_hoc_id',
            value: 'Mã chi nhánh',
          },
          {
            key: 'business_coefficient',
            value: 'Hệ số kinh doanh',
          },
          {
            key: 'dated_paid',
            value: 'Ngày nạp tiền',
          },
          {
            key: 'hop_dong_noi_bo',
            value: 'Hợp đồng nội bộ (?)',
          },
          {
            key: 'nguoi_gt_id',
            value: 'Mã giới thiệu',
          },
          {
            key: 'tvv_id',
            value: 'Mã tư vấn viên',
          },
          {
            key: 'nguon_khach_hang',
            value: 'Nguồn khách hàng',
          },
          {
            key: 'hop_dong_moi',
            value: 'Hợp đồng mới',
          },
          {
            key: 'personal_coefficient',
            value: 'Hệ số Cá nhân',
          },
          {
            key: 'levelup_coefficient',
            value: 'Hệ số lên cấp',
          },
          {
            key: 'kenh_kh',
            value: 'Kênh KH',
          },
        ]
        this.$store.dispatch(EXPORT_CUSTOMER_PAYMENT_V2, {
          ...this.query,
          type_export: 'danh sách đóng tiền',
          date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
          is_danh_sach_dong_tien: 1
        }).then((res) => {
          exportExcel(HEADER_ROW_EXPORT_DSDT, res.data, "Danh sách đóng tiền.xlsx", true)
          this.is_export_excel = false
          if (res.data.error) {
            this.$notify( {
              title: 'Thành công',
              message: res.data.message,
              type: 'success'
            });
          }
        });
      },
    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_DSDT = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'bill_id',
          value: 'Mã hóa đơn',
        },
        {
          key: 'name',
          value: 'Tên học viên',
        },
        {
          key: 'user_id',
          value: 'Mã học viên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'contract_id',
          value: 'Mã hợp đồng',
        },
        {
          key: 'amount',
          value: 'Số tiền nạp',
        },
        {
          key: 'amount_installment',
          value: 'Phí trả góp',
        },
        {
          key: 'center_name',
          value: 'Trung tâm',
        },
        {
          key: 'center_id',
          value: 'Mã trung tâm',
        },
        {
          key: 'chi_nhanh_hoc_dsdt_name',
          value: 'Chi nhánh',
        },
        {
          key: 'chi_nhanh_hoc_dsdt_id',
          value: 'Mã chi nhánh',
        },
        {
          key: 'business_coefficient',
          value: 'Hệ số kinh doanh',
        },
        {
          key: 'dated_paid',
          value: 'Ngày nạp tiền',
        },
        {
          key: 'hop_dong_noi_bo',
          value: 'Hợp đồng nội bộ (?)',
        },
        {
          key: 'nguoi_gt_id',
          value: 'Mã giới thiệu',
        },
        {
          key: 'tvv_id',
          value: 'Mã tư vấn viên',
        },
        {
          key: 'nguon_khach_hang',
          value: 'Nguồn khách hàng',
        },
        {
          key: 'hop_dong_moi',
          value: 'Hợp đồng mới',
        },
        {
          key: 'personal_coefficient',
          value: 'Hệ số Cá nhân',
        },
        {
          key: 'levelup_coefficient',
          value: 'Hệ số lên cấp',
        },
        {
          key: 'kenh_kh',
          value: 'Kênh KH',
        },
        {
          key: 'affiliated_school_id',
          value: 'Mã trường liên kết',
        },
      ]
      this.$store.dispatch(EXPORT_CUSTOMER_PAYMENT, {
        ...this.query,
        type_export: 'danh sách đóng tiền',
        date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
        is_danh_sach_dong_tien: 1
      }).then((res) => {
        console.log(res.data);
        exportExcel(HEADER_ROW_EXPORT_DSDT, res.data, "Danh sách đóng tiền.xlsx", true)
        this.is_export_excel = false
        if (res.data.error) {
          this.$notify( {
            title: 'Thành công',
            message: res.data.message,
            type: 'success'
          });
        }
      });
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data
        }
      })
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_ids: this.query.centerIds
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data
        }
      })
    },
    getSources() {
      this.$store.dispatch(GET_CONTRACT_SOURCES).then((res) => {
        if (!res.error) {
          this.sources = res.data.data
        }
      })
    },
    handleQueryCenter() {
      this.query.branchIds = [];
      this.branches = []
      this.getBranches();
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl();
      this.callApiGetList();
    },
    openDialogUpdate(studentAmount) {
      this.dialogUpdate = true;
      this.currentStudentAmount = studentAmount;
    },
    openDialogHistory(studentAmount) {
      this.dialogHistory = true;
      this.currentStudentAmount = studentAmount;
    },
    changeCustomerChannel(type) {
        switch (type) {
            case 1:
                return 'Đội kinh doanh';
                break;
            case 2:
                return 'Digital marketing';
                break;
            case 3:
                return 'Liên kết trường';
                break;
            default:
                return '';
                break;
        }
    }
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #EBEDF3;
}

.table td {
  text-align: center;
  vertical-align: unset;
}
.col-form-label {
  display: none;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  background: #F3F6F9;
}
</style>
